<template>
  <main>
    <div class="form-wrapper">
      <div class="form-left">
        <img class="desktop" src="/illuform.jpg" />
        <img class="mobile" src="/top-form-mobile.png" />
      </div>
      <div class="form-right">
        <div class="form">
          <!-- <img class="text-form desktop" src="/text-form.png" /> -->
          <Form
            :class="'form_step' + step"
            id="monform"
            :form_id="form_id"
            :server="server"
            v-on:results="getResult"
            v-on:step-up="checkStep"
            v-on:step-down="checkStep"
            v-on:form_submit_loading="loading"
            @on_error="viewError"
            :hideError="hiderror"
          />
        </div>
        <div class="captcha">
          This site is protected by reCAPTCHA and the Google<br />
          <a href="https://policies.google.com/privacy" target="_blank"
            >Privacy Policy</a
          >
          and
          <a href="https://policies.google.com/terms" target="_blank"
            >Terms of Service</a
          >
          apply.
        </div>
      </div>
    </div>
  </main>
</template>
<script>
var consts = require("../config");
import axios from "axios";

export default {
  name: "form",
  data: function() {
    return {
      step: 0,
      hiderror: true,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    checkStep() {
      this.step = this.step++;
    },
    getResult(result) {
      this.$swal.close();
      if (result.lot) {
        if (result.lot === "fleurs") window.lot = 2;
        if (result.lot === "bon") window.lot = 3;
        this.$swal.close();
        this.$router.push("/done");
      } else {
        window.lot = 1;
        this.$router.push("/done");
      }
    },
    viewError(err) {
      console.log(err);

      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        title: "Désolé, une erreur est survenue.",
        text: "",
      }).then(function() {
        window.location.href = "/";
      });
    },
  },
  mounted() {},
  created: function() {},
};
</script>
